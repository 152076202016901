import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { topConnect } from '../../environments/environment';
import jwt_decode from 'jwt-decode';
/* eslint-disable */

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(public http: HttpClient) { }

	login(email: string, password: string) {

		let params = { mail: email, password: password };
		let headers = new HttpHeaders().set('Content-Type', 'application/json');

		return new Promise((resolve, rejected) => {
			this.http.post<any>(topConnect.apiUrl + 'login/', params, { headers: headers })
				.subscribe(

					(data) => {
						localStorage.setItem('access_token', data.token);

						let token: string = localStorage.getItem('access_token')!;

						let decoded: any = jwt_decode(token);
						localStorage.setItem('companyId', decoded.emp);
						localStorage.setItem('brandId', decoded.brn);


						// Para los accesos de los clientes, tenemos estos filtros, 
							// localStorage.setItem('locations','1'); Solo mostramos localizaciones
							// localStorage.setItem('reviews','1'); Solo mostramos reviews
							// localStorage.setItem('data','1'); Solo mostramos data

						if(decoded.rol === "client"){
							// localStorage.setItem('locations','1');
							// localStorage.setItem('reviews','1');
							// localStorage.setItem('data','1');
							resolve(data);
							console.log("POST call successful value returned in body");
	
						}else if(decoded.rol === "client-locations"){
							localStorage.setItem('locations','1');
							resolve(data);
							console.log("POST call successful value returned in body");
	
						}else if(decoded.rol === "client-reviews"){
							localStorage.setItem('reviews','1');
							resolve(data);
							console.log("POST call successful value returned in body");
	
						}
						else{
							localStorage.removeItem('access_token');
							localStorage.setItem('pageReloaded','off');
							rejected("mypanel");
							console.log("POST call in error");
						}

						resolve(data);
						console.log("POST call successful value returned in body",
							data);
					},
					response => {
						rejected(response);
						console.log("POST call in error", response);
					},
					() => {
						console.log("The POST observable is now completed.");
				});
		});

	}
	postEmail(email:string){
		let params = { email: email};
		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return new Promise((resolve, rejected) => {
			this.http.post<any>('${topConnect.apiUrl}forgot-password/', params, { headers: headers })
			.subscribe(

				(data) => {

					resolve(data);
					console.log("POST call successful value returned in body",
						data);
				},
				response => {
					rejected(response);
					console.log("POST call in error", response);
				},
				() => {
					console.log("The POST observable is now completed.");
			});
		})
	}

	postPasswordForgot(password:string,token:any){
		let params = { password: password};
		let headers = new HttpHeaders().set('Content-Type', 'application/json');
		return new Promise((resolve, rejected) => {
			this.http.post<any>(`${topConnect.apiUrl}reset-password/${token}`, params, { headers: headers })
			.subscribe(

				(data) => {

					resolve(data);
					console.log("POST call successful value returned in body",
						data);
				},
				response => {
					rejected(response);
					console.log("POST call in error", response);
				},
				() => {
					console.log("The POST observable is now completed.");
			});
		})
	}
}