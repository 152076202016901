import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { CompanyService } from 'src/app/service/company.service';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/service/filter.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  showErrorMessage: boolean;

  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    public router: Router,
    private filter: FilterService
    ) {
      this.email = '';
      this.password= '';
      this.showErrorMessage = false;
    }

  ngOnInit() {
    if (window.localStorage.getItem('access_token') && window.localStorage.getItem('locations') ){
      this.router.navigate(['/locations']);
    }
    else if (window.localStorage.getItem('access_token') && window.localStorage.getItem('reviews') ){
      this.router.navigate(['/companies-reviews']);
    }
    else{
      this.router.navigate(['/locations']);
    }
    const pageReloaded = window.localStorage.getItem('pageReloaded');
    if (pageReloaded === "off") {
      // Realiza la recarga y marca la página como recargada
      localStorage.setItem("pageReloaded","on")
      location.reload();
    }
  }

  onSubmitLogin(){

    this.authService.login(this.email,this.password).then(res => {
      this.companyService.getCompanyLogged().subscribe((data) => {
        this.filter.changeCompany(data);
      });
      if (window.localStorage.getItem('access_token') && window.localStorage.getItem('locations') ){
        this.router.navigate(['/locations']);
      }
      else if (window.localStorage.getItem('access_token') && window.localStorage.getItem('reviews') ){
        this.router.navigate(['/companies-reviews']);
      } else{
        this.router.navigate(['/locations']);
      }
      // this.router.navigate(['/locations']);
    }).catch(err => this.showErrorMessage = true);
  }

  forgotPassword(){
      this.router.navigate(['send-email-reset-password']);
  }

}
